.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.9rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
    
   
}
.name{
    font-size: 1rem;
}
.header-bg{
   
    transition: 0.5s;
    opacity: 1;
    
}
.li{
    color: #fff;
}
 
.nav-menu {
    display: flex;
}

.nav-menu li{
    padding: 0 1.2px;
}
.nav-menu li a{
    font-size: 12px;
    
}


