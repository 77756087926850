@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Maiandra';
    src: local('Maiandra'), url(../src/assets/fonts/maiandra.ttf) format('truetype');
  }
  @font-face {
    font-family: 'BarlowCondensed';
    src: local('BarlowCondensed'), url(../src/assets/fonts/BarlowCondensed-Regular.ttf) format('truetype');
  }

