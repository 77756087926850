.font-header{
    font-family: 'Maiandra';
}
.font-text{
    font-family: 'BarlowCondensed';
}

@media (max-width: 768px) {
    /* Override responsive styles */
    body {
      max-width: none !important;
    }
  }
  